/** @jsx jsx */

import {jsx} from '@emotion/core'

import styled from "@emotion/styled/macro"
import tw from "tailwind.macro"
import {Layout} from "./Layout";

export const Base = styled.div`
	${tw`flex mx-6`};
`

export const GridNormalizer = styled.div`
	${tw`-mx-6`};
`

const Container = ({ children }) => (
    <Layout>
        <GridNormalizer>
            <Base>
                {children}
            </Base>
        </GridNormalizer>
    </Layout>
)

export default Container;
