/** @jsx jsx */

import React, {Fragment} from 'react'
import styled from "@emotion/styled/macro"
import tw from "tailwind.macro"
import MenuSub from "./MenuSub";
import { jsx, css } from '@emotion/core'
import {Mobile} from "./Responsive";

const Wrap = styled.nav`
	${tw`relative w-full h-full md:h-auto hidden lg:block`};
	-webkit-overflow-scrolling: touch;
`
const menuOpenMobile = css`${tw`block`}`

const MenuItemWrap = styled.div`
    ${tw`flex flex-col`};
`
const MenuItemInner = styled.div`
    ${tw`flex flex-row`};
`
const MenuItemList = styled.div`
    ${tw`flex flex-col lg:flex-row px-4 lg:px-menu-x`};
`
const MenuItemElement = styled.a`
    ${tw`block font-bold tracking-wide py-3 px-4 text-white-light uppercase`};
    flex-grow: 1;
`
const MenuItemActive = css`${tw`text-white`}`

const MenuItemSubMobileToggle = styled.button`
    ${tw`px-5 py-2 lg:hidden`};
    flex-grow: 0;
    flex-shrink: 0;
    line-height: 1;
`
const MenuItemSubMobileActive = css`
    transform: rotate(90deg);
`

const MobileLanguage = css`
    ${tw`border-t-default border-b-default border-white`}
`

export class MenuItem extends React.Component {

    toggleSubmenu = () => {
        if (this.props.onToggleSubmenu) {
            this.props.onToggleSubmenu()
        }
    }

    render() {
        const {title, href, isSubmenuActive, children} = this.props
        const isLinkActive = href === window.location.href

        return (
            <MenuItemWrap>
                <MenuItemInner>
                    <MenuItemElement href={href} css={isLinkActive ? MenuItemActive : {}}>{title}</MenuItemElement>
                    { children ? (
                        <MenuItemSubMobileToggle
                            className={'chevron'}
                            onClick={this.toggleSubmenu}
                            css={isSubmenuActive ? MenuItemSubMobileActive : {}} />
                    ) : null }
                </MenuItemInner>
                {children}
            </MenuItemWrap>
        )
    }
}

export default class Menu extends React.Component {

    state = {
        activeSubmenu: -1
    }

    onMouseEnter = (index) => {
        if (window.innerWidth < 1023) return
        this.toggleSubmenu(index)
    }

    onMouseLeave = () => {
        if (window.innerWidth < 1023) return
        this.toggleSubmenu(-1)
    }

    toggleSubmenu = (index) => {
        if (index === this.state.activeSubmenu) {
            index = -1
        }
        this.setState({activeSubmenu: index})
    }

    render() {
        const {isMenuOpen, menuItems, languages} = this.props
        const {activeSubmenu} = this.state

        return (
            <Wrap className={'menu'} css={isMenuOpen ? menuOpenMobile: {}}>
                <MenuItemList>
                    {menuItems.map((item, index) => (
                        <Fragment key={index}>
                            <div onMouseEnter={() => this.onMouseEnter(index)} onMouseLeave={this.onMouseLeave}>
                                <MenuItem
                                    key={index}
                                    title={item.name}
                                    href={item.url}
                                    isSubmenuActive={activeSubmenu === index}
                                    onToggleSubmenu={() => this.toggleSubmenu(index)}
                                >
                                    {item.children.length ? (
                                        <MenuSub isActive={index === activeSubmenu} menuItems={item.children} />
                                    ) : null}
                                </MenuItem>
                            </div>
                        </Fragment>
                    ))}
                    <Mobile css={MobileLanguage}>
                        {languages.map((item, index) => (
                            <Fragment key={index}>
                                <div onMouseEnter={() => this.onMouseEnter(index)} onMouseLeave={this.onMouseLeave}>
                                    <MenuItem
                                        key={index}
                                        title={item.language}
                                        href={item.href || item.url}
                                        isSubmenuActive={activeSubmenu === index}
                                        onToggleSubmenu={() => this.toggleSubmenu(index)}
                                    />
                                </div>
                            </Fragment>
                        ))}
                    </Mobile>
                </MenuItemList>
            </Wrap>
        )
    }
}

