/** @jsx jsx */

import {jsx} from '@emotion/core'

import styled from "@emotion/styled/macro"
import tw from "tailwind.macro"

export const LayoutWrap = styled.div`
	${tw`mx-auto w-full`};
	max-width: 1280px;
`

export const Content = styled.div`
	${tw`mx-auto w-full`};
	max-width: 780px;
`

export const ContentIntro = styled.div`
	${tw`mx-auto w-full pt-8 lg:pt-32`};
	max-width: 780px;
	padding-bottom: 100px;
`

export const Layout = ({ children , css }) => (
    <LayoutWrap css={css}>
        {children}
    </LayoutWrap>
)

export default Layout;
