/** @jsx jsx */

import {css, jsx} from '@emotion/core'
import styled from "@emotion/styled/macro";
import tw from "tailwind.macro";

const Wrap = styled.div`
	${tw`flex lg:-mx-4 w-full`};
	max-width: 1280px;
	justify-content: space-between;
`

const orderRegular = css(tw`flex-col lg:flex-row lg:justify-between`)
const orderReverse = css(tw`flex-col lg:flex-row-reverse`)

const ElementWide = styled.div`
	${tw`px-4 pl-4 lg:w-8/12`};
`

const ElementTight = styled.div`
	${tw`px-4 lg:my-0 lg:w-3/12`};
`

export const ColumnMDWide = ({ children }) => (
    <ElementWide>
        {children}
    </ElementWide>
)

export const ColumnMDTight = ({ children }) => (
    <ElementTight>
        {children}
    </ElementTight>
)

export const ColumnMDContainer = ({ children, reverseOrder }) => (
    <Wrap css={!reverseOrder ? orderRegular : orderReverse }>
        {children}
    </Wrap>
)
