/** @jsx jsx */

import React, {Component} from 'react'
import {css, jsx} from '@emotion/core'
import styled from "@emotion/styled/macro";
import tw from "tailwind.macro";

import Title from "../Title";
import Text from "../../components/Text";

const Base = styled.div`
    ${tw`mb-8 px-4 lg:px-0`}
`

class TextBlock extends Component {

    static defaultProps = {
        titleTag: 'h2'
    }

    render() {
        const {headline, body, titleTag} = this.props

        return (
            <Base className="textblock">
                {
                    headline ? (
                        <Title titleTag={titleTag} title={headline} mobileCenter={true} bordered={true} />
                    ) : null
                }
                <Text text={body} />
            </Base>
        )
    }
}

export default TextBlock
