
let instance = null;

export default class AuthService {

    userData = {}

    constructor(){
        if (instance){
            return instance;
        }

        this.instance = this;

        this.loadConfigs()
    }

    loadConfigs() {
        if (typeof window === 'undefined') return false
        const data = JSON.parse(window.document.getElementById('data').innerHTML);
        this.userData = data.user || null
        return true
    }

    hasUserData() {
        return this.userData && Object.entries(this.userData).length > 0
    }

    checkConfigs() {
        const hasUserData = this.hasUserData()
        if (!hasUserData) {
            return this.loadConfigs()
        }
        return true
    }

    isAuth() {
        return this.checkConfigs() && this.userData && this.userData.token !== undefined && this.userData.token.length === 39
    }

    isAdmin() {
        return this.checkConfigs() && this.userData && this.userData.token !== undefined && this.userData.isAdmin === "1"
    }

    async login(login, pass) {
        return await fetch('/index.php?p=actions/users/login', {
            credentials: "include",
            headers: {
                "accept": "application/json, text/javascript, */*; q=0.01",
                "cache-control": "no-cache",
                "content-type": "application/x-www-form-urlencoded; charset=UTF-8",
                "pragma": "no-cache",
                'x-csrf-token' : window.csrfTokenValue
            },
            method: "POST",
            mode: "cors",
            body: `loginName=${encodeURIComponent(login)}&password=${pass}&rememberMe=y`
        })
    }

    async register(username, email, password, account) {
        return await fetch('/index.php?p=actions/users/save-user', {
            credentials: "include",
            headers: {
                "accept": "application/json, text/javascript, */*; q=0.01",
                "cache-control": "no-cache",
                "content-type": "application/x-www-form-urlencoded; charset=UTF-8",
                "pragma": "no-cache",
                'x-csrf-token' : window.csrfTokenValue
            },
            method: "POST",
            mode: "cors",
            body: `email=${encodeURIComponent(email)}&password=${password}&username=${username}&fields[affiliation]=${account.affiliation}&fields[position]=${account.position}`
        })
    }


    async logout(){
        return await fetch('/index.php?p=actions/users/logout', {
            credentials: "include",
            headers: {
                "accept": "application/json, text/javascript, */*; q=0.01",
                "cache-control": "no-cache",
                "content-type": "application/x-www-form-urlencoded; charset=UTF-8",
                "pragma": "no-cache",
                'x-csrf-token' : window.csrfTokenValue
            },
            method: "GET",
            mode: "cors",
        })
    }

}

