/** @jsx jsx */
import React, {Component} from 'react'

import styled from "@emotion/styled/macro"
import {css, jsx} from "@emotion/core";
import tw from "tailwind.macro"
import Title from "../Title";
import Container from "../../components/grid/Container";
import {ColumnMDContainer, ColumnMDTight, ColumnMDWide} from "../grid/ColumnMasterDetail";
import Text from "../../components/Text";

const Wrap = styled.div`
    ${tw`mb-8`};
`

const Image = styled.img`
    ${tw`w-3/4 mx-auto mb-8 lg:w-full lg:mb-0`};
`

class TextImage extends Component {

    static defaultProps = {
        titleTag: 'h2',
        inlineHeadline: false,
        reverseOrder: false,
    }

    state = {
        menuOpen: false
    }

    render() {
        const {image, headline, titleTag, body, inlineHeadline, rowReverse} = this.props

        return (
            <Wrap className="textimage">
                { !inlineHeadline ? (
                    <Container padded={false} paddedTop={true}>
                        <Title titleTag={titleTag} title={headline} mobileCenter={true} bordered={true} />
                    </Container>
                ) : null}
                <Container padded={inlineHeadline} paddedBottom={!inlineHeadline}>
                    <ColumnMDContainer reverseOrder={rowReverse}>
                        <ColumnMDTight>
                            {image ? (
                                <Image src={image} alt=""/>
                            ) : null}
                        </ColumnMDTight>
                        <ColumnMDWide>
                            { inlineHeadline ? (
                                <Title titleTag={titleTag} title={headline} mobileCenter={true} borderedY={true} />
                            ) : null}
                            <Text text={body} />
                        </ColumnMDWide>
                    </ColumnMDContainer>
                </Container>
            </Wrap>
        )
    }
}

export default TextImage
