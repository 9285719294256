import "./styles/index.css";
import "./polyfill"
import {hydrate, render} from "react-dom";

import React, {Component} from "react";
import styled from "@emotion/styled/macro"
import tw from "tailwind.macro"

import PageFooter from "./components/PageFooter";
import ContentMapper from "./helpers/ContentMapper";
import Navbar from "./components/Navbar";
import {Layout} from "./components/grid/Layout";
import {LoginModal} from "./components/LoginForm";
import AuthService from "./services/AuthService";
import {RegisterModal} from "./components/RegisterForm";

const isIE11 = !!window.MSInputMethodContext && !!document.documentMode;
if (isIE11) {
    document.body.classList.add('ie11')
}


const Base = styled.div`
  ${tw`bg-black text-base`};
`;


export default class App extends Component {

    state = {
        isMenuOpen: false,
        isAuth: false,
        isAdmin: false,
        showLoginModal: false,
        showRegisterModal: false,
        data: {}
    }

    _toggleMenu = (isMenuOpen) => {
        this.setState({isMenuOpen})
    }

    _showLoginModal = (status) => {
        this.setState({showLoginModal: true})
    }

    _showRegisterModal = (status) => {
        this.setState({showRegisterModal: true})
    }

    _closeModal = () => {
        this.setState({
            showLoginModal: false,
            showRegisterModal: false
        })
    }

    componentDidMount() {
        if (typeof window === 'undefined') {
            return
        }
        const data = JSON.parse(window.document.getElementById('data').innerHTML);
        const authService = new AuthService();
        this.setState({data, isAuth: authService.isAuth(), isAdmin: authService.isAdmin()})
    }

    renderContent() {
        const { entry } = this.state.data
        if (!entry) return null // todo: 404?
        return ContentMapper.mapSection(entry)
    }

    render() {
        const { menu, entry } = this.state.data
        const { isAuth, isAdmin, showLoginModal, showRegisterModal } = this.state

        let mainMenu = false
        if (menu && menu.mainMenu !== 'undefined') {
            mainMenu = menu.mainMenu
        }

        return (
            <div className="app">
                {
                    !isAuth && showLoginModal && (
                        <LoginModal onModalClose={this._closeModal} />
                    )
                }
                {
                    !isAuth && showRegisterModal && (
                        <RegisterModal onModalClose={this._closeModal} />
                    )
                }
                <Base>
                    { mainMenu ? (
                        <Navbar
                            menuItems={mainMenu}
                            isAuth={isAuth}
                            isAdmin={isAdmin}
                            editUrl={entry.cpEditUrl}
                            languages={this.state.data.lang || []}
                            isMenuOpen={this.state.isMenuOpen}
                            onMenuToggle={this._toggleMenu}
                            onShowLoginForm={this._showLoginModal} />
                    ) : null}
                    <Layout css={{backgroundColor: 'white'}}>
                        { this.renderContent() }
                    </Layout>
                    <PageFooter />
                </Base>
            </div>
        );
    }
}

if (typeof window !== 'undefined') {
    render(<App ref={(appComponent) => {window.appComponent = appComponent}} />, window.document.getElementById('root'));
}

