import React, {Component} from 'react'

import styled from "@emotion/styled/macro"
import tw from "tailwind.macro"
import Title from "../Title";
import Container from "../../components/grid/Container";
import {ColumnMDContainer, ColumnMDTight, ColumnMDWide} from "../grid/ColumnMasterDetail";
import Text from "../../components/Text";


const Base = styled.div`
    ${tw`block w-full`};
    span + span {
        margin-left: 20px
    }
`


const ImageList = styled.div`
    ${tw`mb-8 flex flex-row`};
`

const ImageElement = styled.img`
    flex-grow: 0;
    flex-shrink: 1;
    flex-basis: auto;
    width: auto;
`

class ImageRow extends Component {

    render() {
        const {images} = this.props

        return (
            <Base>
                <ImageList className="imagerow">
                    {
                        images.map((imageUrl, idx) => (
                            <span key={idx}>
                                <ImageElement src={imageUrl} />
                            </span>
                        ))
                    }
                </ImageList>
            </Base>
        )
    }
}

export default ImageRow
