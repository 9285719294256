/** @jsx jsx */

import React, {Component} from 'react'

import styled from "@emotion/styled/macro"
import tw from "tailwind.macro"

import SVG from "react-inlinesvg";
import {css, jsx} from "@emotion/core";
import AuthService from "../../services/AuthService";

const Base = styled.div`
    ${tw`flex flex-col w-full items-center pb-10`};
`
const baseInverted =  css(`
    color: white;
    * { stroke: #fff }
`)

const InfoText = styled.p`
    ${tw`text-md pt-3`}
`

const ActionButtons = styled.div`
    ${tw`flex flex-row items-center`}
`

const ActionButton = styled.a`
    ${tw`flex flex-row text-md pt-3 mx-1 underline cursor-pointer`}
`

const SVGWrap = styled.span`
   ${tw`block mx-2 cursor-pointer`}
   transform: scale(0.8);
   * { stroke: #000 }
`

class LoginInfo extends Component {

    authService = new AuthService()

    render() {
        if (this.authService.isAuth()) return null
        const { inverted } = this.props
        const svgPath = !inverted ? 'img/icon-locked.svg' : 'img/icon-locked-white.svg'

        return (
            <Base css={inverted ? baseInverted : {}}>
                <SVG src={svgPath} />
                <InfoText>Please login for full access.</InfoText>
                <ActionButtons>
                    <ActionButton onClick={() => window.appComponent._showRegisterModal()}>Register</ActionButton>
                    <ActionButton onClick={() => window.appComponent._showLoginModal()}><SVGWrap><SVG src={'/img/icon-login.svg'} /></SVGWrap>Login</ActionButton>
                </ActionButtons>
            </Base>
        )
    }
}

export default LoginInfo
