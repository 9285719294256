/** @jsx jsx */

import {Fragment} from 'react'

import {css, jsx} from '@emotion/core'
import styled from "@emotion/styled/macro";
import tw from "tailwind.macro";
import {getStyles} from "../utils";

const Headline1 = styled.h1`
`
const Headline2 = styled.h2`
`
const Headline3 = styled.h3``
const Headline4 = styled.h4``

const Content = styled.span`
    ${tw`pb-6 block`}
`

const white = css(tw`text-white`)
const center = css(tw`text-center block`)
const mobileCenter = css(tw`text-center  lg:text-left`)

const styleables = {
    white,
    center,
    mobileCenter,
}

export const Title = (props) => {

    const { title, titleTag } = props
    const styles = getStyles(props, styleables)

    if (titleTag === 'h1') {
        return (
            <Headline1>
                <Content css={styles}>
                    {title ? title.split('\n').map((item, key) => {
                                return <Fragment key={key}>{item}<br/></Fragment>
                            }) : null}
                </Content>
            </Headline1>
        )
    }
    if (titleTag === 'h2') {
        return (
            <Headline2>
                <Content css={styles}>
                    {title ? title.split('\n').map((item, key) => {
                                return <Fragment key={key}>{item}<br/></Fragment>
                            }) : null}
                </Content>
            </Headline2>
        )
    }
    if (titleTag === 'h3') {
        return (
            <Headline3>
                <Content css={styles}>
                    {title ? title.split('\n').map((item, key) => {
                                return <Fragment key={key}>{item}<br/></Fragment>
                            }) : null}
                </Content>
            </Headline3>
        )
    }
    if (titleTag === 'h4') {
        return (
            <Headline4>
                <Content css={styles}>
                    {title ? title.split('\n').map((item, key) => {
                                return <Fragment key={key}>{item}<br/></Fragment>
                            }) : null}
                </Content>
            </Headline4>
        )
    }
    return (<div dangerouslySetInnerHTML={{ __html: title }} />)
}

export default Title
