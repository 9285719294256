import React from 'react'

import styled from "@emotion/styled/macro"
import tw from "tailwind.macro"
import {Image} from "./Hero";

export const Base = styled.div`
    ${tw`relative mx-0 sm:-mx-4 flex flex-row flex-wrap`};
`

export const CellBase = styled.a`
    ${tw`relative block w-full lg:max-w-1/2 mb-0 px-4 lg:px-0 max-w-full`};
    transition: all 0.125s ease;
    height: 230px;
    background: radial-gradient(ellipse at top, #696969, #B6B6B6);
    border: 15px solid white;
    :hover {
        background: radial-gradient(ellipse at top, #762326, #CC4045);
    }
`

export const HeroHeadline = styled.h6`
    ${tw`text-center text-white tracking-wide absolute text-center font-display font-normal flex flex-col w-full justify-center h-full items-center uppercase`};
    top: 0px;
    left: 0px;
    > div {
        max-height: 500px;
    }
`

export const Icon = styled.img`
    ${tw`mx-auto mb-4`}
    height: 50px;
`

export const HeroSubline = styled.span`
    ${tw`block font-default text-md tracking-wide`};
`

const IconCellLinks = ({iconcells}) => {
    return (
        <Base className="iconcelllinks">
            {
                iconcells.map((iconcell, idx) => (
                    <IconCell key={idx} {...iconcell} />
                ))
            }
        </Base>
    )
}

const IconCell = ({headline, icon, link}) => {
    return (
        <CellBase href={link}>
            <HeroHeadline>
                <div>
                    {icon && (
                        <Icon src={icon} />
                    )}
                    {headline}
                </div>
            </HeroHeadline>
        </CellBase>
    )
}

export default IconCellLinks
