/** @jsx jsx */

import {Component} from "react"

import {jsx} from '@emotion/core'
import styled from "@emotion/styled/macro"
import tw from "tailwind.macro"
import AuthService from "../services/AuthService";
import { CSSTransition } from "react-transition-group";

const ModalOverlay = styled.div`
    ${tw`fixed top-0 left-0 bottom-0 right-0 bg-black-overlay flex flex-col items-center justify-center`}
    z-index: 2000
`

const ModalInner = styled.div`
    ${tw`bg-brand px-8 py-10 relative`}
    width: 100%;
    max-width: 500px;
`

const ModalClose = styled.a`
    ${tw`bg-brand px-3 py-1 bg-white absolute top-0 right-0 cursor-pointer`}
`

const Animation = styled(CSSTransition)`
  ${({ transitionName }) => `.${transitionName}-enter`} {
    opacity: 0;
  }

  ${({transitionName}) => `.${transitionName}-leave`} {
    opacity: 1;
  }
`

const animationID = 'some-hashed-text'


export const LoginModal = ({onModalClose}) => (
        <ModalOverlay>
            <ModalInner>
                <ModalClose onClick={() => onModalClose()}>x</ModalClose>
                <LoginForm />
            </ModalInner>
        </ModalOverlay>
)

const FormBase = styled.form`
    ${tw`flex flex-col text-black`}
`

const FormText = styled.input`
    ${tw`px-2 py-4 bg-transparent text-white border-b-default border-white mb-2`}
`

const FormButton = styled.button`
    ${tw`bg-white mt-4 self-end px-8 py-2 mt-8 -mb-8 text-sm font-bold`}
    flex-grow: 0;
`

export class LoginForm extends Component {

    login = async (e) => {
        e.preventDefault()

        const {login, pass} = this.state
        const authService = new AuthService();
        await authService.login(login, pass);

        window.location.reload()
    }

    handleChange = (e) => {
        let state = {}
        state[e.target.id] = e.target.value
        this.setState(state)
    }

    state = {
        login: '',
        pass: ''
    }

    render() {
        const {login, pass} = this.state
        const isLoginDisabled = login.length < 3 || pass.length < 3

        return (
            <FormBase className={'login'} onSubmit={this.login}>
                <input type="hidden" name="redirect" value="/" />
                <FormText type="text" id={'login'} placeholder={'Email'} value={login} onChange={this.handleChange} />
                <FormText type="password" id={'pass'} placeholder={'Password'} value={pass } onChange={this.handleChange}  />
                <FormButton css={{
                    opacity: isLoginDisabled ? 0.5 : 1,
                    cursor: isLoginDisabled ? 'not-allowed' : ''
                }} disabled={isLoginDisabled} type="submit">LOGIN</FormButton>
            </FormBase>
        )
    }

}

export default LoginForm
