/** @jsx jsx */

import React, {Fragment} from 'react'
import {css, jsx} from '@emotion/core'

import styled from "@emotion/styled/macro"
import tw from "tailwind.macro"
import Menu from "./Menu";
import SVG from "react-inlinesvg";
import AuthService from "../services/AuthService";
import MenuToggle from "./MenuToggle";

const Wrap = styled.nav`
	${tw`bg-black-overlay w-screen text-white lg:h-menu-h fixed flex flex-col lg:flex-row`};
    z-index: 100;
`

const AuthButton = styled.button`
	${tw`flex flex-row mx-3 items-center uppercase font-bold tracking-wide py-3 px-4 text-white-light uppercase`};
	> * {margin-left: 5px; margin-right: 5px}
	line-height: 1;
	opacity: 0.6
`

const AuthLink = styled.a`
	${tw`flex flex-row py-3 px-4 items-center uppercase font-bold  hidden lg:block`};
	> * {margin-left: 5px; margin-right: 5px;}
	opacity: 0.6;
	line-height: 1.6;
`

const MobileMenuTop = styled.div`
    ${tw`flex flex-row-reverse lg:hidden py-4 px-2`};
`

const MenuBase = styled.div`
    ${tw`hidden lg:flex lg:flex-row`};
    max-width: 1280px;
    margin: 0 auto;
    width: 100%;
`
const MenuBaseMobileActive = css`
    ${tw`flex flex-col`}
`

export default class Navbar extends React.Component {

    _showLoginForm = () => {
        if (this.props.onShowLoginForm)
            this.props.onShowLoginForm()
    }

    async _logout() {
        const authService = new AuthService();
        await authService.logout()
        window.location.reload()
    }

    async _goAdmin() {
        window.location = `${window.location.origin}/admin`
    }

    _renderUserActions() {
        const {isAuth, isAdmin, editUrl, languages} = this.props

        let userActions = []

        if (languages.length) {
            languages.forEach(lang => {
                userActions.push(
                    <AuthLink key={lang.language} href={lang.href}><span>{lang.language}</span></AuthLink>
                )
            })
        }

        if (!isAuth) {
            userActions.push(
                <AuthButton key={'auth'} onClick={this._showLoginForm}><SVG
                    src={'/img/icon-login.svg'}/><span>Login</span></AuthButton>
            )
            return userActions
        }

        if (isAdmin) {
            userActions.push(<AuthLink href={'/admin'} key={'admin'}><span>Admin</span></AuthLink>)
            if (editUrl) {
                userActions.push(<AuthLink href={editUrl} key={'edit'}><span>Edit</span></AuthLink>)
            }
        }

        userActions.push(<AuthButton key={'logout'} onClick={this._logout}><span>Logout</span></AuthButton>)

        return (
            <Fragment>
                {userActions}
            </Fragment>
        )
    }

    render() {
        const {isMenuOpen, onMenuToggle, languages, menuItems} = this.props

        return (
            <Wrap className={'menu'}>
                <MobileMenuTop>
                    <MenuToggle isOpen={isMenuOpen} onMenuToggle={onMenuToggle}/>
                </MobileMenuTop>
                <MenuBase css={isMenuOpen ? MenuBaseMobileActive : null}>
                    <Menu isMenuOpen={isMenuOpen} menuItems={menuItems} languages={languages}/>
                    {this._renderUserActions()}
                </MenuBase>

            </Wrap>
        )
    }
}