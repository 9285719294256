import React, {Component, Fragment} from 'react'

import styled from "@emotion/styled/macro"
import tw from "tailwind.macro"
//import Title from "../Title";

export const Base = styled.div`
    ${tw`relative mb-8 lg:mb-16`};
`

export const Image = styled.img`
    ${tw`relative`};
`

export const HeroHeadlineBlock = styled.h1`
    ${tw`text-center text-white absolute text-center font-headline font-normal flex flex-col w-full justify-center h-full items-center`};
    top: 0px;
    left: 0px;
    > div {
        max-width: 500px;
    }
    font-size: 1rem;
    @media (min-width: 768px) {
        font-size: inherit;
    }
`

export const HeroHeadline = styled.span`
    ${tw`hidden`}
`

export const HeroSubline = styled.span`
    ${tw`block font-default`};
`

const HeadlineImage = styled.img`
    ${tw`inline-block mb-4`}
    height: 36px;
`

class Hero extends Component {
    state = {
        menuOpen: false
    }

    render() {
        const {headline, heroHeadlineImage, subline, backgroundimage} = this.props


        return (
            <Base>
                {backgroundimage && (
                    <Image src={backgroundimage.url} />
                )}
                <HeroHeadlineBlock>
                    <div>
                        {heroHeadlineImage ? (
                            <Fragment>
                                <HeadlineImage src={heroHeadlineImage} alt=""/>
                                <HeroHeadline>
                                    {headline}
                                </HeroHeadline>
                            </Fragment>
                        ) : (
                            <span>{headline}</span>
                        )}
                        <HeroSubline>{subline}</HeroSubline>
                    </div>
                </HeroHeadlineBlock>
            </Base>
        )
    }
}
export default Hero
