/** @jsx jsx */

import {jsx} from '@emotion/core'
import styled from "@emotion/styled/macro"
import tw from "tailwind.macro"

export const Mobile = styled.div`
    ${tw`block lg:hidden`}
`

export const Desktop = styled.div`
    ${tw`hidden lg:block`}
`
