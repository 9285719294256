/** @jsx jsx */

import {jsx} from '@emotion/core'
import styled from "@emotion/styled/macro"
import tw from "tailwind.macro"
import {Content} from "./grid/Layout";

const Footer = styled.footer`
    ${tw`bg-grey py-1 text-center mx-auto text-white`};
`
const FooterBase = styled.div`
	${tw`mx-auto px-20 lg:px-0 w-full flex flex-col lg:flex-row justify-between`};
	max-width: 780px;	
`

const Logo = styled.img`
    ${tw`mx-auto my-8 block w-full`};
`

const LogoWrap = styled.a`
    ${tw`flex flex-col justify-center`};
`

export const PageFooter = (props) => {
    return (
        <Footer className={'page--footer'}>
            <FooterBase>
                <LogoWrap href={'https://www.hup.harvard.edu/'} target="_blank">
                    <Logo src="/img/logo-harvard-university-press.svg" css={{height: 50, maxWidth: 360}} alt=""/>
                </LogoWrap>
                <LogoWrap href={'https://www.geschkult.fu-berlin.de/'} target="_blank">
                    <Logo src="/img/logo-freie-uni-berlin.svg" css={{height: 80, maxWidth: 360}} alt=""/>
                </LogoWrap>
            </FooterBase>
        </Footer>
    )
}

export default PageFooter
