/** @jsx jsx */

import {css, jsx} from '@emotion/core'
import styled from "@emotion/styled/macro"
import tw from "tailwind.macro"
import {getStyles} from "../utils";

const Element = styled.p`
    ${tw`mb-10`};
    + p {
        display: none;
        margin-top: 8px;
    }
`

const ElementPure = styled.div`
    p + p {
        margin-top: 20px;
    }
`


const left = css(tw`text-left`)
const center = css(tw`text-center`)
const white = css(tw`text-white`)
const brand = css(tw`text-brand`)
const muted = css(tw`text-grey-bright italic`)
const small = css(tw`text-sm`)
const fontDisplay = css(tw`font-display`)
const marginless = css(tw`m-0`)
const quote = css(tw`block bg-grey px-4 py-3`)

const styleables = {
    white,
    muted,
    small,
    fontDisplay,
    brand,
    marginless,
    left,
    center,
    quote
}

export const Text = (props) => {
    const { text, children } = props
    const styles = getStyles(props, styleables)
    if (text) {
        return (<ElementPure className={'content'} css={styles} dangerouslySetInnerHTML={{ __html: text }} />)
    }
    return (<Element className={'content'} css={styles}>{children}</Element>)
}

export default Text
