import React from 'react'
import styled from "@emotion/styled/macro"
import tw from "tailwind.macro"
import Layout from "./grid/Layout";
import {css, jsx} from "@emotion/core";
import {Desktop, Mobile} from "./Responsive";

const Wrap = styled.nav(
    [tw`w-screen text-white lg:fixed`,{
        left: 0,
        top: 49
    }],
    props => ({ display: props.isActive ? 'block' : 'none' })
)

const LayoutPosition = css`
    ${tw`flex flex-row lg:bg-red-overlay`}
    transform: translateX(-7px);
`

const MenuItemList = styled.div`
    ${tw`flex flex-col pl-8 lg:pl-menu-x w-full `};
`
const MenuItemElement = styled.a`
    ${tw`text-white uppercase py-2 block relative `};
    .chevron {
        position: absolute;
        right: 0;
        top: 10px;
    }
`

const MenuItem = ({title, href, hasChildren}) => (
    <MenuItemElement href={href}>{title}{hasChildren && <Desktop><span className="chevron"></span></Desktop>}</MenuItemElement>
)

export default class MenuSub extends React.Component {

    state = {
        activeSubmenu: -1
    }

    onMouseEnter = (index) => {
        if (window.innerWidth < 1023) return
        this.toggleSubmenu(index)
    }

    onMouseLeave = () => {
        return
        if (window.innerWidth < 1023) return
        this.toggleSubmenu(-1)
    }

    toggleSubmenu = (index) => {
        if (index === this.state.activeSubmenu) {
            index = -1
        }
        this.setState({activeSubmenu: index})
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!this.props.isActive && prevProps.isActive) {
            this.toggleSubmenu(-1)
        }
    }

    render() {
        const {isActive, menuItems} = this.props
        const {activeSubmenu} = this.state

        return (
            <Wrap className={'menu'} isActive={isActive}>
                <Layout css={LayoutPosition}>
                    <MenuItemList>
                        {menuItems.map((item, index) => (
                            <div key={index} onMouseEnter={() => this.onMouseEnter(index)} onMouseLeave={this.onMouseLeave}>
                                <MenuItem title={item.name} href={item.url} hasChildren={item.children.length > 0} />
                                <Mobile>
                                    {item.children.map((item, idxItem) => (
                                        <MenuItemList key={idxItem}>
                                            <MenuItem title={item.name} href={item.url}/>
                                        </MenuItemList>
                                    ))}
                                </Mobile>
                            </div>
                        ))}
                    </MenuItemList>
                    <Desktop>
                        {menuItems.map((list, index) => {
                            return list.children.length ? (
                                activeSubmenu === index && (
                                    <MenuItemList key={index}>
                                        {list.children.map((item, idxItem) => (
                                            <MenuItem key={idxItem} title={item.name} href={item.url}/>
                                        ))}
                                    </MenuItemList>
                                )
                            ) : null
                        })}
                    </Desktop>
                </Layout>
            </Wrap>
        )
    }
}
