import React from 'react'

import styled from "@emotion/styled/macro"
import tw from "tailwind.macro"
import {Image} from "./Hero";


export const Base = styled.div`
    ${tw`relative sm:-mx-4 flex flex-row flex-wrap`};
`

export const CellBase = styled.a`
    ${tw`relative max-w-full mb-8 sm:px-4 sm:max-w-1/2 hover:opacity-75`};
    transition: all 0.125s ease;
`

export const HeroSubline = styled.span`
    ${tw`block font-default text-md tracking-wide`};
`

const ImageCellLinks = ({imagecells}) => {
    return (
        <Base className="imagecelllinks">
            {
                imagecells.map((imagecell, idx) => (
                    <ImageCell key={idx} {...imagecell} />
                ))
            }
        </Base>
    )
}

const HeadlineImage = styled.img`
    ${tw`inline-block`}
    height: 24px;
`

const HeroHeadline = styled.h1`
    ${tw`text-center text-white absolute text-center font-headline font-normal flex flex-col w-full justify-center h-full items-center`};
    top: 0px;
    left: 0px;
    > div {
        max-width: 500px;
    }
`

const ImageCell = ({headlineImage, subline, image, link}) => {
    return (
        <CellBase href={link}>
            {image && (
                <Image src={image} />
            )}
            <HeroHeadline>
                <div>
                    <HeadlineImage src={headlineImage} alt=""/>
                    <HeroSubline>{subline}</HeroSubline>
                </div>
            </HeroHeadline>
        </CellBase>
    )
}

export default ImageCellLinks
