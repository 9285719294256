import React, {Component, Fragment} from 'react'

import styled from "@emotion/styled/macro"
import tw from "tailwind.macro"
import TextBlock from "./TextBlock";
import AuthService from "../../services/AuthService";
import LoginInfo from "./LoginInfo";
import SVG from "react-inlinesvg";
import {jsx} from "@emotion/core";

const Base = styled.div`
    ${tw`block w-full relative`};
`
const ListWrap = styled.ul`
    ${tw`flex flex-col lg:flex-row lg:flex-wrap w-full lg:-mx-4`};
`
const ListItem = styled.li`
    ${tw`w-full lg:w-1/2`};
`

const UnauthBlurWrap = styled.div`
    filter: blur(2px);
`
const UnauthWrap = styled.div``
const LoginOverlay = styled.div`
    ${tw`bg-black-overlay w-screen h-full absolute pt-20`};
    top: 0;
    left: 50%;
    transform: translate(-50vw, 0px);
    z-index: 80;
`

const PreviewOverlay = styled.div`
    ${tw`bg-black-overlay-dark fixed top-0 left-0 w-screen h-screen text-white`};
    z-index: 9999;
`

const PreviewOverlayInner = styled.div`
    ${tw`mx-auto mt-20 relative`};
    max-width: 500px;
    max-height: 100%;
`

const PreviewOverlayClose = styled.div`
    ${tw`absolute top-0 right-0 cursor-pointer`};
    transform: translate(75%,-75%);
    `

class DownloadList extends Component {

    authService = new AuthService()

    state = {
        previewDownload: null
    }

    _showPreview = (previewDownload) => {
        this.setState({previewDownload})
    }

    render() {

        const isAuth = this.authService.isAuth()
        const {headline,body,loginRequired,downloads} = this.props
        const {previewDownload} = this.state

        const innerContent = (
            <Fragment>
                <TextBlock headline={headline} body={body} />
                    <ListWrap>
                        {
                            downloads.map((download, idx) => (
                                <ListItem key={idx}>
                                    { download.file ? (
                                        <DownloadItem {...download} />
                                    ) : (
                                        <DownloadPreviewItem onShowPreview={() => this._showPreview(download)} {...download} />
                                    )}
                                </ListItem>
                            ))
                        }
                    </ListWrap>
            </Fragment>
        )

        let content = innerContent

        if (loginRequired && !isAuth) {
            content = (
                <UnauthWrap>
                    <UnauthBlurWrap>
                        {innerContent}
                    </UnauthBlurWrap>
                    <LoginOverlay>
                        <LoginInfo inverted={true} />
                    </LoginOverlay>
                </UnauthWrap>
            )
        }

        return (
            <Base>
                { previewDownload ? (
                    <PreviewOverlay>
                        <PreviewOverlayInner>
                            <PreviewOverlayClose onClick={() => this._showPreview(null)}>
                                <SVG src={'/img/icon-close.svg'} />
                            </PreviewOverlayClose>
                            <DownloadItem {...previewDownload} />
                        </PreviewOverlayInner>
                    </PreviewOverlay>
                ) : null}
                { content }
            </Base>
        )
    }
}

const DownloadBase = styled.a`${tw`block w-full p-4 cursor-pointer`}`
const DownloadPreviewBase = styled.div`${tw`block w-full p-4 cursor-pointer`}`
const PreviewImage = styled.img`${tw`w-full`}`
const DownloadTitle = styled.span`${tw`block pt-2`}`

const DownloadItem = ({file, headline, image} = this.props) => (
    <DownloadBase target="_blank" href={file}>
        <PreviewImage src={image} />
        <DownloadTitle>{headline}</DownloadTitle>
    </DownloadBase>
)

const DownloadPreviewItem = ({headline, image, onShowPreview} = this.props) => (
    <DownloadPreviewBase onClick={() => onShowPreview()}>
        <PreviewImage src={image} />
        <DownloadTitle>{headline}</DownloadTitle>
    </DownloadPreviewBase>
)


export default DownloadList
