/** @jsx jsx */

import {Component} from 'react'

import {css, jsx} from '@emotion/core'
import styled from "@emotion/styled/macro"
import tw from "tailwind.macro"
import Text from "../../components/Text";
import {Layout} from "../../components/grid/Layout";
import {SingleCenter} from "../../components/grid/SingleCenter";

import SVG from "react-inlinesvg";
import ContentHelper from "../../helpers/ContentHelper";
import Title from "../Title";
import ContentMapper from "../../helpers/ContentMapper";

const ElementWrap = styled.div`
    ${tw`w-full`}
`

const ElementTrigger = styled.div`
    ${tw`font-display text-xl text-left text-brand py-4 border-t-default border-black cursor-pointer flex flex-row align-top relative`}
`

const ElementExpansion = styled.div`
    ${tw`text-left overflow-hidden relative`}
`

const ElementOpen = css(`
    max-height: auto;
`)

const ElementClosed = css(`
    max-height: 250px;
    :before { 
        content: '' 
    }

`)

const ElementWrapLast = css(tw`border-b-8 border-brand`)
const TitleWrap = styled.div`
    ${tw`flex flex-col pr-8`}
`

const SVGWrap = styled.span`
    position: absolute;
    right: -10px;
    padding: 10px;
    transform: rotate(-90deg);
    transition: transform 0.25s ease;
`

export class NewsTeaser extends Component {

    state = {
        isOpen: false
    }


    render() {
        const {entry, isLast} = this.props
        const {title, date} = this.props.entry
        const {isOpen} = this.state

        const titleTag = 'h2'
        const postDate = ContentHelper.formatEntryDate(date)

        const { contentBlocks } = entry

        return (
            <ElementWrap css={isLast ? ElementWrapLast : {}}>
                <ElementTrigger onClick={ () => this.setState({isOpen: !isOpen})}>
                    <TitleWrap>
                        <Title titleTag={titleTag} title={title}  />
                        <div css={{ marginTop: '-25px'}}>
                            <Text muted small marginless>
                                {postDate}
                            </Text>
                        </div>
                    </TitleWrap>
                    <SVGWrap css={isOpen ? {transform: 'rotate(0deg)'} : {}}>
                        <SVG src={'/img/icon-chevron-circle.svg'} />
                    </SVGWrap>
                </ElementTrigger>
                <ElementExpansion className={isOpen ? '' : 'has-more'} css={isOpen ? ElementOpen : ElementClosed}>
                    {ContentMapper.mapContentBlocks(contentBlocks)}
                </ElementExpansion>
            </ElementWrap>
        )
    }
}

export default NewsTeaser
