/** @jsx jsx */

import React, {Component} from 'react'

import { jsx } from '@emotion/core'
import styled from "@emotion/styled/macro"
import tw from "tailwind.macro"
import Title from "../../components/Title";
import Text from "../../components/Text";
import ContentHelper from "../../helpers/ContentHelper";
import Container, {Wrap} from "../../components/grid/Container";
import NewsTeaser from "./NewsTeaser";

const Base = styled.div`
  ${tw``};
  width: 100%;
`;

const NewsList = styled.div`
  ${tw`flex flex-col`}
`

class NewsChannel extends Component {

    static defaultProps = {
        titleTag: 'h2',
    }

    state = {
        menuOpen: false
    }

    render() {
        const {entries, white} = this.props
        return (
            <Base className="news-channel">
                    <NewsList>
                        {
                            entries.map((entry, index) => (
                                <NewsTeaser key={index} entry={entry} />
                            ))
                        }
                    </NewsList>
            </Base>
        )
    }
}

export default NewsChannel
