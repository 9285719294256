/** @jsx jsx */

import React, {Fragment} from 'react'
import {jsx} from '@emotion/core'
import tw from "tailwind.macro"

import styled from "@emotion/styled/macro"
import ContentMapper from "../../helpers/ContentMapper";
import {ContentIntro} from "../grid/Layout";
import HeaderSmall from "../HeaderSmall";

const Base = styled.article`
    ${tw`px-8 lg:px-0`}
`

export default class Page extends React.Component {

    renderContent() {
        const { entry, children } = this.props
        if (!entry || !entry.contentBlocks) {
            return  (
                <Fragment>
                    {children}
                </Fragment>
            )
        }
        const { contentBlocks } = entry
        return ContentMapper.mapContentBlocks(contentBlocks)
    }


    render() {
        return (
            <Base>
                <HeaderSmall />
                <ContentIntro>
                    {this.renderContent()}
                </ContentIntro>
            </Base>
        )
    }

}