/** @jsx jsx */

import {Component, Fragment} from "react"

import {jsx} from '@emotion/core'
import styled from "@emotion/styled/macro"
import tw from "tailwind.macro"
import AuthService from "../services/AuthService";

const ModalOverlay = styled.div`
    ${tw`fixed top-0 left-0 bottom-0 right-0 bg-black-overlay flex flex-col items-center justify-center`}
    z-index: 2000
`

const ModalInner = styled.div`
    ${tw`bg-brand px-8 py-10 relative`}
    width: 100%;
    max-width: 500px;
`

const ModalClose = styled.a`
    ${tw`bg-brand px-3 py-1 bg-white absolute top-0 right-0 cursor-pointer`}
`

export const RegisterModal = ({onModalClose}) => (
    <ModalOverlay>
        <ModalInner>
            <ModalClose onClick={() => onModalClose()}>x</ModalClose>
            <RegisterForm onClose={() => onModalClose()} />
        </ModalInner>
    </ModalOverlay>
)

const FormBase = styled.form`
    ${tw`flex flex-col text-white`}
`

const FormText = styled.input`
    ${tw`px-2 py-4 bg-transparent text-white border-b-default border-white mb-2`}
`

const FormButton = styled.button`
    ${tw`bg-white mt-4 self-end px-8 py-2 mt-8 -mb-8 text-sm font-bold text-black`}
    flex-grow: 0;
`

const FormHeadline = styled.h6`
    ${tw`text-white font-bold mb-4`}
`

export class RegisterForm extends Component {

    state = {
        action: 'users/save-user',

        username: '',
        email: '',
        affiliation: '',
        position: '',
        password: '',

        isSuccess: false,
        isError: false
    }

    register = async (e) => {
        e.preventDefault()

        const {username, email, password, affiliation, position} = this.state
        const authService = new AuthService();
        let status = await authService.register(username, email, password, {
            affiliation, position
        });

        if (status.ok) {
            this.setState({isSuccess: true})
        } else {
            alert('Error');
        }
    }

    closeError = () => { this.setState({isError: false, isSuccess: false})}

    componentDidMount() {
        fetch("http://localhost:8000/index.php?p=actions/users/save-user", {"credentials":"include","headers":{"accept":"application/json, text/javascript, */*; q=0.01","accept-language":"de-DE,de;q=0.9,en-US;q=0.8,en;q=0.7,la;q=0.6,it;q=0.5","cache-control":"no-cache","content-type":"application/x-www-form-urlencoded; charset=UTF-8","pragma":"no-cache","x-csrf-token":"Nj7yIiiFWMZBneKJ2MgtZH_TVeNmWsI62tDdg2jfMBfEuJ9wumF53FkPvGxithPwGOmy4IDlXCMLggOXLmnyTJyEvOsChmRgheLqROgnQY4="},"referrer":"http://localhost:8000/downloads","referrerPolicy":"no-referrer-when-downgrade","body":"email=wurstfreund2%40outlook.de&password=123geheim&username=wres&affiliation=af&position=po","method":"POST","mode":"cors"});
    }

    handleChange = (e) => {
        let state = {}
        state[e.target.id] = e.target.value
        this.setState(state)
    }

    render() {
        const {username, email, password, affiliation, position, isSuccess, isError} = this.state
        const {onClose} = this.props
        const isRegisterDisabled = username.length < 3 || password.length < 3

        return (
            <FormBase className={'login'} onSubmit={this.register}>
                {
                    !isSuccess && !isError  ? (
                        <Fragment>
                            <FormHeadline>Registration</FormHeadline>
                            <input type="hidden" name="redirect" value="/" />
                            <FormText required type="text" id={'username'} placeholder={'Name'} value={username} onChange={this.handleChange} />
                            <FormText required type="text" id={'affiliation'} placeholder={'Affiliation'} value={affiliation} onChange={this.handleChange} />
                            <FormText required type="text" id={'position'} placeholder={'Position'} value={position} onChange={this.handleChange} />
                            <FormText required type="email" id={'email'} placeholder={'Email'} value={email} onChange={this.handleChange} />
                            <FormText required type="password" id={'password'} placeholder={'Password'} value={password} onChange={this.handleChange}  />
                            <FormButton css={{
                                opacity: isRegisterDisabled ? 0.5 : 1,
                                cursor: isRegisterDisabled ? 'not-allowed' : ''
                            }} disabled={isRegisterDisabled} type="submit">REGISTER</FormButton>
                        </Fragment>
                    ) : null
                }
                {
                    isSuccess && (
                        <Fragment>
                            <FormHeadline style={{color: 'white'}}>Success</FormHeadline>
                            <p>Your account has been created. After reviewing
                                your credentials you will get an email to activate your account</p>
                            <FormButton onClick={onClose}>OK</FormButton>
                        </Fragment>
                    )
                }
                {
                    isError && (
                        <Fragment>
                            <FormHeadline style={{color: 'white'}}>Error</FormHeadline>
                            <p>Something is wrong with your credentials</p>
                            <FormButton onClick={this.closeError}>OK</FormButton>
                        </Fragment>
                    )
                }
            </FormBase>
        )
    }

}

export default RegisterForm
