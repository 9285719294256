import React from "react";
import styled from "@emotion/styled/macro"
import tw from "tailwind.macro"

const HeaderWrap = styled('header')`
  ${tw`w-full`};
  height: 204px;
  background-image: url(/img/header-bg-small.jpg);
  background-size: cover;
  @media all and (max-width: 1023px) {
    width: calc(100% + 80px);
    position: relative;
    margin-left: -40px;
  } 
`;

const HeaderSmall = () => (
    <HeaderWrap />
);

export default HeaderSmall;
