import React from "react";
import TextImage from "../components/blocks/TextImage";
import Accordeon from "../components/blocks/Accordeon";
import Default from "../components/sections/Default";
import Page from "../components/sections/Page";
import TextBlock from "../components/blocks/TextBlock";
import Hero from "../components/blocks/Hero";
import ImageCellLinks from "../components/blocks/ImageCellLinks";
import BlockQuote from "../components/blocks/BlockQuote";
import NewsChannel from "../components/blocks/NewsChannel";
import ImageRow from "../components/blocks/ImageRow";
import DownloadList from "../components/blocks/DownloadList";
import LoginInfo from "../components/blocks/LoginInfo";
import IconCellLinks from "../components/blocks/IconCellLinks";

class ContentMapper {

    static mapSection(entry) {
        switch (entry.type) {
            case 'page':
                return <Page entry={entry} />
            case 'homepage':
            default:
                return <Default entry={entry} />
        }
    }

    static mapContentBlocks(contentBlocks) {
        return contentBlocks.map((block, i) => {
            switch (block.type) {
                case 'bookquote':
                    return (
                      <BlockQuote key={i} {...block}  />
                    );
                case 'hero':
                    return (
                        <Hero
                            key={i}
                            {...block} />
                    )
                case 'text':
                    return (
                        <TextBlock
                            key={i}
                            {...block}
                        />
                    )
                case 'images':
                    return (
                        <ImageRow
                            key={i}
                            {...block}
                        />
                    )
                case 'loginInfo':
                    return (
                        <LoginInfo
                            key={i}
                            {...block}
                        />
                    )
                case 'downloadlist':
                    return (
                        <DownloadList
                            key={i}
                            {...block}
                        />
                    )
                case 'textImage':
                    return (
                        <TextImage
                            key={i}
                            {...block}
                            rowReverse={block.layout.includes('rowReverse')}
                            inlineHeadline={block.layout.includes('inlineHeadline')}
                        />
                    )
                case 'blocks':
                    switch (block.blockType) {
                        case 'newsList':
                            return (
                                <NewsChannel key={i} entries={block.entries}/>
                            )
                        default: break;
                    }
                    return (
                        <TextImage
                            key={i}
                            {...block}
                            reverseOrder={true}/>
                    )
                case 'imagecelllinks':
                    return (
                        <ImageCellLinks key={i} {...block} />
                    )
                case 'iconcelllinks':
                    return (
                        <IconCellLinks key={i} {...block} />
                    )
                case 'accordeon':
                    return (
                        <Accordeon key={i} {...block} />
                    )
                default:
                    console.info(`missing blocktype: ${block.type}`)
                    break;
            }
            return null
        }).filter(b => b)
    }
}

export default ContentMapper
