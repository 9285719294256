import React from "react";
import styled from "@emotion/styled/macro"
import tw from "tailwind.macro"

const HeaderWrap = styled('header')`
  ${tw`flex flex-col md:flex-row`};
`;

const BookHeader = styled('img')`
    ${tw`flex-grow`};
    object-fit: cover;
    @media (min-width: 768px) {
        width: 65%;
        height: 100%
    }
`

const LogoWrap = styled('div')`
    ${tw`flex-grow flex flex-col p-10 justify-center`};
    @media (min-width: 768px) {
        max-width: 35%
    }
    flex-grow: 1;
    background: linear-gradient(to bottom,rgba(255,255,255,1) 0,rgba(185,195,205,1) 100%);
`

const Logo = styled('img')`
    max-width: 50%
    @media (min-width: 768px) {
        max-width: 100%
    }
`

const Header = () => (
    <HeaderWrap className={'page-header'}>
        <BookHeader className={'book-logo'} src="/img/header-bg.png" alt=""/>
        <LogoWrap>
            <Logo src="/img/make-china-modern.svg" alt=""/>
        </LogoWrap>
    </HeaderWrap>
);

export default Header;
