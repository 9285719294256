/** @jsx jsx */

import React, {Component, Fragment} from 'react'
import {ColumnMDContainer, ColumnMDTight, ColumnMDWide} from "../grid/ColumnMasterDetail";

import {jsx} from '@emotion/core'
import styled from "@emotion/styled/macro"
import tw from "tailwind.macro"
import Text from "../Text";
import SVG from 'react-inlinesvg';

const Headline = styled.h2`
    ${tw`w-full border-b-default pb-1 border-black text-left mb-6`};
`

const QuoteSelectButton = styled.button`
    ${tw`w-full text-left mb-4`};
`

const HiddenTextarea = styled.textarea`
    ${tw`absolute`};
    top: 0px;
    left: -9999px;
`
const ClipboardButton = styled.button`
    ${tw`flex flex-row mx-auto items-center text-brand tracking-wider`};
    > * { margin-left: 5px; margin-right: 5px }
`

const InfoText = styled.button`
    ${tw`block font-bold mx-auto mt-4`};
`

class BlockQuote extends Component {

    static quotationTypes = {
        'MLA7': 'MLA (7th Edition)',
        'APA6': 'APA (6th Edition)',
        'CT15': 'Chicago/Turabian (15th Edition)',
        'MLA8': 'MLA (8th Edition)',
        'HV1': 'Harvard 1'
    }

    state = {
        selectedType: 'MLA7',
        hasCopied: false
    }

    copyToClipboard = () => {
        if (typeof window === 'undefined') return false
        this.quoteRef.select();
        window.document.execCommand('copy');
        this.setState({hasCopied : true})
        setTimeout(() => {
            this.setState({hasCopied : false})
        }, 1000)
    }

    render() {
        const {selectedType, hasCopied} = this.state
        return (
            <div className="blockquote">
                <Headline>Quote</Headline>
                <ColumnMDContainer>
                    <ColumnMDTight>
                        {
                            Object.keys(this.constructor.quotationTypes).map(type => (
                                <QuoteSelectButton
                                    key={type}
                                    css={{fontWeight: selectedType === type ? 'bold' : 'normal'}}
                                    onClick={() => this.setState({selectedType: type})}>
                                    {this.constructor.quotationTypes[type]}
                                </QuoteSelectButton>
                            ))
                        }
                    </ColumnMDTight>
                    <ColumnMDWide>
                        <Text quote text={this.props[selectedType]} />
                        <br/>
                        <HiddenTextarea readonly ref={ref => (this.quoteRef = ref)} defaultValue={this.props[selectedType]} />
                        <ClipboardButton onClick={this.copyToClipboard}>
                            {
                                hasCopied ? (
                                    <Fragment>
                                        <span>COPIED TO CLIPBOARD!</span>
                                    </Fragment>
                                ) : (
                                    <Fragment>
                                        <SVG src={'/img/icon-quote.svg'} />
                                        <span>COPY TO CLIPBOARD</span>
                                    </Fragment>
                                )
                            }
                        </ClipboardButton>
                        <InfoText>Please Check the Completeness of Reference</InfoText>
                    </ColumnMDWide>
                </ColumnMDContainer>
            </div>
        )
    }
}

export default BlockQuote
